<template>
  <v-card min-height="600">
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
          class="mt-8 mb-0"
        >
          <v-autocomplete
            v-model="selectedReseller"
            :items="resellers"
            item-text="name"
            item-value="id"
            label="Reseller"
            :loading="isResellersLoading"
            outlined
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
        >
          <table-filter
            :table-name="'payments'"
            default-date-picker-date="Last 7 days"
            @filter="applyFilters"
          />
        </v-col>
      </v-row>
      <v-row
        class="match-height mt-4"
      >
        <v-col>
          <revenue-card
            :current-revenue-name="'Total Amount Received'"
            tooltip-text="The total money received by the platform"
            :current-revenue="totalRevenueReceived"
            :is-revenue-loading="isTotalRevenueReceivedLoading"
          />
        </v-col>
        <v-col>
          <revenue-card
            :current-revenue-name="'Total Operation Charges'"
            tooltip-text="The total amount deducted from resellers' payments as operation charges"
            :current-revenue="totalOperationCharges"
            :is-revenue-loading="isTotalOperationChargesLoading"
          />
        </v-col>
        <v-col>
          <revenue-card
            :current-revenue-name="'Net Pay'"
            tooltip-text="The total amount received by the platform after deducting operation charges"
            :current-revenue="netPay"
            :is-revenue-loading="isNetPayLoading"
          />
        </v-col>
        <v-col>
          <revenue-card
            :current-revenue-name="'Wallet Balance'"
            tooltip-text="The current balance of the wallet"
            :current-revenue="walletBalance"
            :is-revenue-loading="isWalletBalanceLoading"
          />
        </v-col>
      </v-row>

      <v-row
        justify="center"
        class="mt-4"
      >
        <v-btn
          v-show="selectedReseller !== 'all'"
          class="primary text-center"
          @click="showWithdrawDialog = true"
        >
          Withdraw
        </v-btn>
        <v-dialog
          v-model="showWithdrawDialog"
          max-width="600px"
        >
          <v-card>
            <withdrawal-form
              :reseller-id="selectedReseller"
              :reseller-name="selectedResellerName"
              :phone-number="selectedResellerPhoneNumber"
              :wallet-balance="selectedResellerBalance"
              @balanceUpdated="onWithdrawalSuccess"
            />
          </v-card>
        </v-dialog>
      </v-row>

      <v-card class="mt-8 mb-4">
        <v-card-title class="mt-6 mb-2">
          Payments
        </v-card-title>
        <v-card-body>
          <v-data-table
            :headers="headers"
            :items="transactions"
            :loading="isPaymentsLoading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear
                indeterminate
                class="mt-2"
                rounded
              ></v-progress-linear>
              <p class="text-center mt-1">
                Loading...Please wait
              </p>
            </template>
            <template v-slot:body="{ items }">
              <tbody
                v-if="transactions.length > 0"
              >
                <tr
                  v-for="transaction in items"
                  :key="transaction.transaction_reference"
                >
                  <td>
                    <span class="primary--text font-weight-medium">{{ transaction.account_number }}</span>
                  </td>
                  <td>
                    <span class="primary--text font-weight-medium">{{ transaction.name }}</span>
                  </td>
                  <td>{{ transaction.phone_number }}</td>
                  <td>{{ transaction.transaction_reference }}</td>
                  <td>{{ transaction.amount }}</td>
                  <td>{{ transaction.operation_fee }}</td>
                  <td>{{ transaction.net_pay }}</td>
                  <td>{{ transaction.transaction_time | formatDate }}</td>
                  <td v-show="selectedReseller === 'all'">
                    {{ transaction.meter_station_name }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-show="!isPaymentsLoading">
                  <td
                    :colspan="headers.length"
                    style="text-align: center"
                  >
                    No transactions found
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <data-table-pagination
            :page="pagination.page"
            :page-count="pagination.pageCount"
            @page-change="onPageChange"
            @items-per-page-change="onItemsPerPageChange"
          />
        </v-card-body>
      </v-card>
      <v-card class="mt-12 mb-4">
        <withdrawal-history-table
          ref="withdrawalTable"
          url="reseller-withdrawal-history"
          :reseller-id="selectedReseller"
        />
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import TableFilter from '@/components/partials/table-filter/index.vue'
import RevenueCard from '@/views/super-admin/Payments/components/PaymentCard.vue'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'
import WithdrawalHistoryTable from '@/components/tables/WithdrawalHistoryTable.vue'
import WithdrawalForm from '@/components/partials/WithdrawalForm.vue'

export default {
  name: 'TransactionStatistics',
  components: {
    TableFilter,
    RevenueCard,
    DataTablePagination,
    WithdrawalHistoryTable,
    WithdrawalForm,
  },
  data() {
    return {
      filters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
      },
      transactionUrl: 'payments',
      isPaymentsLoading: false,
      showWithdrawDialog: false,
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      sortBy: ['transaction_time'],
      sortDesc: [true],
      search: '',
      headers: [
        {
          text: 'Account number',
          value: 'account_number',
          sortable: false,
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Phone number',
          value: 'phone_number',
          sortable: false,
        },
        {
          text: 'Reference',
          value: 'transaction_reference',
          sortable: false,
        },
        { text: 'Amount', value: 'amount' },
        { text: 'Operation Charge', value: 'operation_charge' },
        { text: 'Net Pay', value: 'net_pay' },
        { text: 'Date', value: 'transaction_time' },
      ],
      activeSearchFilter: 'users.account_number',
      searchFilters: [
        {
          text: 'Account number',
          value: 'users.account_number',
        },
        {
          text: 'Name',
          value: 'users.name',
        },
        {
          text: 'Phone number',
          value: 'mpesa_transactions.MSISDN',
        },
        {
          text: 'Reference',
          value: 'mpesa_transactions.TransID',
        },
        {
          text: 'Amount',
          value: 'mpesa_transactions.TransAmount',
        },
      ],
      transactions: [],
      isResellersLoading: false,
      selectedReseller: 'all',
      resellers: [],
      transactionHistory: [],
      isTotalRevenueReceivedLoading: false,
      isTotalOperationChargesLoading: false,
      isNetPayLoading: false,
      isTransactionHistoryLoading: false,
      isWalletBalanceLoading: false,
      totalRevenueReceived: '',
      totalOperationCharges: '',
      netPay: '',
      walletBalance: '',
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.transactionUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
    selectedResellerData() {
      // Find the selected reseller in the resellers array
      return this.resellers.find(
        reseller => reseller.id === this.selectedReseller,
      )
    },
    selectedResellerName() {
      return this.selectedResellerData?.name || null
    },
    selectedResellerPhoneNumber() {
      return this.selectedResellerData?.latest_phone_number || null
    },
    selectedResellerBalance() {
      return this.selectedResellerData?.account_balance || null
    },
  },
  watch: {
    selectedReseller() {
      this.getRevenue()
      this.getTransactions()
      this.getWalletBalance()
    },
  },
  mounted() {
    this.getResellers()
    this.getWalletBalance()
    this.getTransactions()
  },
  methods: {
    applyFilters(filters) {
      this.filters = filters
      this.getRevenue()
      this.getTransactions()
      this.getWalletBalance()
    },
    getResellers() {
      this.isResellersLoading = true
      axios.get('resellers?all=true').then(response => {
        this.resellers = response.data
        this.resellers.unshift({
          id: 'all',
          name: 'All resellers',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        })
      }).finally(() => {
        this.isResellersLoading = false
      })
    },
    getRevenue() {
      this.isTotalRevenueReceivedLoading = true
      this.isTotalOperationChargesLoading = true

      const params = {
        fromDate: this.filters.dates.fromDate,
        toDate: this.filters.dates.toDate,
        resellerId: this.selectedReseller,
      }

      axios
        .get('statistics/revenue', { params })
        .then(response => {
          this.totalRevenueReceived = response.data.totalRevenue
          this.totalOperationCharges = response.data.totalOperationCharges
          this.netPay = response.data.netRevenue
        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          this.isTotalRevenueReceivedLoading = false
          this.isTotalOperationChargesLoading = false
        })
    },
    getTransactions: _.debounce(function () {
      this.isPaymentsLoading = true
      axios
        .get(this.constructCompleteTransactionUrl())
        .then(response => {
          this.transactionsSum = response.data.sum
          this.transactions = response.data.transactions.data
          this.pagination.page = response.data.transactions.current_page
          this.pagination.pageCount = response.data.transactions.last_page
          this.pagination.itemsPerPage = response.data.transactions.per_page
          this.isPaymentsLoading = false
          this.isTransactionsRefreshing = false
        })
        .catch(error => {
          this.isPaymentsLoading = false
          this.isTransactionsRefreshing = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    getWalletBalance() {
      this.isWalletBalanceLoading = true
      axios.get(`reseller-balance/${this.selectedReseller}`)
        .then(response => {
          this.walletBalance = response.data.balance
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
        .finally(() => {
          this.isWalletBalanceLoading = false
        })
    },
    refreshTransactions() {
      this.isTransactionsRefreshing = true
      this.getTransactions()
    },
    onTransactionClick(transactionId) {
      this.$router.push({ name: 'transaction-details', params: { id: transactionId } })
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getTransactions()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getTransactions()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
    constructCompleteTransactionUrl() {
      return `${this.transactionUrl}?fromDate=${this.filters.dates.fromDate}&toDate=${this.filters.dates.toDate}&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&search_filter=${this.activeSearchFilter}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}&resellerId=${this.selectedReseller}`
    },
    onWithdrawalSuccess() {
      this.showWithdrawDialog = false
      this.$refs.withdrawalTable.$emit('refresh-table')
      this.getWalletBalance()
      this.getTransactions()
      this.getResellers()
    },
  },
}
</script>

<style scoped>
.custom-tab-items {
    background-color: transparent !important;
}
</style>
